<template>
   <button class="btn login-btn" @click="$emit('click')">
    <img src="@/assets/images/apple_icon.svg" />
    <span>CONTINUE WITH APPLE</span>
  </button>
</template>

<script>
  export default {
    name: 'AppleLoginBtn'
  }
</script>

<style scoped lang="scss">
  @import '@/assets/stylesheets/variables.scss';
  @import '@/assets/stylesheets/login-btn.scss';

  .login-btn {
    background-color: $color-black;
    color: $color-white;

    img {
      box-sizing: border-box;
      width: 52px;
      height: 52px;
      padding: 9px;
    }
  }
</style>