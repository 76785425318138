<template>
   <button class="btn login-btn" @click="$emit('click')">
    <img src="@/assets/images/google_icon.svg" />
    <span>CONTINUE WITH GOOGLE</span>
  </button>
</template>

<script>
  export default {
    name: 'GoogleLoginBtn'
  }
</script>

<style scoped lang="scss">
  @import '@/assets/stylesheets/variables.scss';
  @import '@/assets/stylesheets/login-btn.scss';

  .login-btn {
    background-color: #4285F4;
    color: $color-white;

    img {
      background: $color-white;
      border-radius: 2px;
    }
  }
</style>