<template>
  <div class="wrapper login">
    <loader :loading="loading"/>

    <div class="login-form">
      <div>
        <h1>Sign in</h1>
        <input type="text" name="email" placeholder="Email" @keyup.enter="signIn" v-model="email">

        <p>
          <input :type="showPassword ? 'text' : 'password'" name="password" placeholder="password" @keyup.enter="signIn" v-model="password">

          <i @click="showPassword = !showPassword"
             class="far fa-eye eyespan"
             v-if="!showPassword" ></i>

          <i @click="showPassword = !showPassword"
             class="far fa-eye-slash eyespan"
             v-else ></i>
        </p>
      </div>

      <div class="error">
        <div>{{ errorMessage }}</div>
      </div>

      <nuxt-link :to="{ name: 'forgot_password' }">
        <p class="forgot-password" >Forgot your password?</p>
      </nuxt-link>

    </div>

    <div class="buttons-container">
      <div class="btn singIn" @click="signIn">SIGN IN</div>
      <p>or</p>
      <div class="btn facebook" @click="singInWithFacebook">
        <img src="@/assets/images/facebook_icon.png" />
        <span>CONTINUE WITH FACEBOOK</span>
      </div>
      <google-login-btn @click="singInWithGoogle"/>
      <apple-login-btn @click="singInWithApple"/>
      <p>Don't have an Account?</p>

      <nuxt-link :to="{name: 'signup'}">
        <div class="btn">CREATE AN ACCOUNT</div>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
  import AuthMixin from '@/mixins/auth'

  export default {
    name: 'Login',
    mixins: [AuthMixin],
    head () {
      return {
        title: 'User login at Scout & Nimble',
        link: [
          {
            rel: 'canonical',
            href: `${this.$config.baseUrl}${this.$route.path}`
          }
        ],
        meta: [
          {
            name: 'description',
            content: 'Login to a user account at Scout & Nimble and get access to the user dashboard'
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/stylesheets/animations/fade';
  @import '@/assets/stylesheets/variables';

  .wrapper {
    width: 90%;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0 auto;

    &.login {
      max-width: 480px;
      margin-top: 20px;
    }

    .login-form {
      border: 1px solid #CCCCCC;

      h1 {
        font-size: 2rem !important;
        font-weight: normal;
        line-height: 65px;
        border-bottom: 1px solid #CCCCCC;
        margin:0;
        padding-left: 16.5px;
        letter-spacing: 1px;
      }

      input {
        width: 90%;
        margin: 30px 5% 0 5%;
        font-family: 'OpenSans';
        font-size: 1.8rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.43px;
        text-align: left;
        border-bottom: 2px solid #6c6c6c;
        color: #6c6c6c;
      }

      .error{
        color: #dd5353;
        text-align: center;
        font-size: 1.2rem;
      }

      .eyespan {
        float: right;
        font-size: 2.2rem;
        margin-right: 25px;
        margin-top: -37px;
        position: relative;
        z-index: 2;
        cursor: pointer;
      }

      .checkbox {
        display: none;
        margin-left: 5%;
        margin-top: 20px;
        font-size: 1.6rem;
        color: #2e2e2e;
        letter-spacing: 0.8px;
        position: relative;
        line-height: 23px;
        margin-bottom: 20px;
      }

      .forgot-password {
        margin: 30px 0 25px 0;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.7px;
        color: #428bca;
        text-decoration: underline;
      }
    }

    .buttons-container {
      margin-top: 22px;

      .btn {
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.48px;
        line-height: 38px;
        border-radius: 2px;
        box-shadow: -3px 5px 15px #CCC;

        &.facebook {
          border: none;
          background-color: #4267b2;
          color: $color-white;

          img {
            line-height: 60px;
            max-height: 36px;
            float: left;
            transform: translateX(10px);
          }
        }
      }

      p {
        margin: 10px;
        font-size: 2.4rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        font-family: 'Tenez';
        letter-spacing: 1.2px;
        text-align: center;
        color: #151515;
      }

      p:last-of-type {
        margin: 30px 1px;
        text-align: left;
        font-size: 2rem;
      }
    }
  }
</style>
